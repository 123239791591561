import React, { useState, useEffect } from "react";
import TopHeader from "../component/TopHeader";
import Secondheader from "../component/Second_header";
import SkyBlueBox from "../component/SkyBlueBox";
import ServiceRequestItem from "../component/ServiceRequestItem";
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlineDateRange } from "react-icons/md";
import PopupComponent2 from "../component/CamPopup";
import '../component/css/Camdetails.css';
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";


const CAMdetails = () => {
  const [activeSection, setActiveSection] = useState("ServiceTickets");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [machineDetails, setMachineDetails] = useState(null);
  const [serviceRequests, setServiceRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingServiceRequests, setLoadingServiceRequests] = useState(true);
  const [engineerNotes, setEngineerNotes] = useState([]);
  const [loadingEngineerNotes, setLoadingEngineerNotes] = useState(true);

  const navigate = useNavigate();

  const paramsFromUrl = useParams();

  useEffect(() => {

    const params = {
      Record_Id: paramsFromUrl.id,
    };

    axios.get('/cma1_machine_details.php', { params })
      .then(response => response.data)
      .then(data => {
        setMachineDetails(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching machine details:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (machineDetails) {
      const params = {
        Record_Id: paramsFromUrl.id,
      };

      axios.get('/cma3_service_requests_list.php', { params })
        .then(response => response.data)
        .then(data => {
          console.log('Service Requests Data:', data);
          if (data && data["Service Tickets"]) {
            setServiceRequests(data["Service Tickets"]);
          } else {
            console.error('Service Requests Data is not an array or is missing:', data);
            setServiceRequests([]);
          }
          setLoadingServiceRequests(false);
        })
        .catch(error => {
          console.error('Error fetching service requests:', error);
          setLoadingServiceRequests(false);
        });
    }
  }, [machineDetails]);

  useEffect(() => {
    const params = {
      CMA_Machine_Id: paramsFromUrl.id,
    };

    axios.get('/cma2_engineer_notes.php', { params })
      .then(response => response.data)
      .then(data => {
        setEngineerNotes(data.engineerNotes);
        setLoadingEngineerNotes(false);
      })
      .catch(error => {
        console.error('Error fetching engineer notes:', error);
        setLoadingEngineerNotes(false);
      });
  }, []);



  if (loading) {
    return (
      <div className="loader-container">
        <div style={{ textAlign: 'center', marginTop: '250px' }} className="loader"></div>
      </div>
    );
  }

  const handleCreateServiceRequestClick = (recordId) => {
    setIsPopupOpen(true);
    setRecordId(recordId);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleButtonClick = (section) => {
    setActiveSection(section);
    setIsPopupOpen(false);
  };

  if (!machineDetails) {
    return <div>Loading...</div>;
  }

  const {
    "Machine Name": machineName,
    "CMA Start Date": cmaStartDate,
    "Calibration Date": calibrationDate,
    "Assigned Engineer": assignedEngineer,
    "Manufacturer": manufacturer,
    "Model": model,
    "CMA Balance": cmaBalance,
    "CMA End Date": cmaEndDate,
    "Machine Category": machineCategory,
    "Status": status,
  } = machineDetails.data;

  const skyBlueBoxData = [
    {
      imageUrl: "https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Men.png",
      name: assignedEngineer.Name,
      email: assignedEngineer.Email,
      phone: assignedEngineer.Mobile,
    },
  ];

  const machineInfoData = [
    {
      label: "CMA Start Date",
      value: cmaStartDate,
    },
    {
      label: "CMA End Date",
      value: cmaEndDate,
    },
    {
      label: "Calibration Date",
      value: calibrationDate,
    },
  ];

  const machineInfoDatas = [
    {
      label: "Manufacturer",
      value: manufacturer,
    },
    {
      label: "Model",
      value: model,
    },
    {
      label: "Machine Category",
      value: machineCategory,
    }
  ];

  return (
    <div className="CAMdetails_box">
      <TopHeader />
      <Secondheader />
      <div className="Cma_box">
        <div className="Top_for">
          <div className="Top_for-text">
            <h2 className="machine-name">{machineName}</h2>
          </div>
          <a href="" className="Back" onClick={() => navigate('/CMAMachines')}>
            Back
          </a>
        </div>
        <div className="Three_buttons">
          {machineInfoData.map((item, index) => (
            <div key={index} className={`Boxes_pros ${index === 0 ? 'first-box' : ''}`}>
              <h4>{item.label}</h4>
              <p>
                <div style={{
                  fontSize: '13px',
                  paddingTop: '10px',
                  alignItems: 'center',
                  display: 'flex',
                  color: index === 0 ? 'white' : index === 1 ? 'red' : '#017cc2'
                }}>
                  {index === 0 ? (
                    <CiCalendarDate color="white" fontSize={20} />
                  ) : (
                    <MdOutlineDateRange
                      color="Red" fontSize={24} />
                  )}
                  {item.value}
                </div>
              </p>
            </div>
          ))}
        </div>
        <br></br>
        <div className="CMABalance_chart">
          <h4 className="balance-heading">CMA Balance</h4>
          <div className="Group_">
            {Object.entries(cmaBalance).map(([key, value]) => (
              <div key={key} className="group_t">
                <h5 className="group-key-heading">{key}</h5>
                <h3 className="value-count">{value}</h3>
              </div>
            ))}
          </div>
        </div>
        <br></br>
        <div className="Two_boxes">
          <div className="First_BOX">
            <div className="Image-Box Display_flex">
              <img
                src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/demo.png"
                className="Product_img"
                alt="Machine"
              />
              <div className="Inside-Display_flex">
                <div className="Top_numbers">
                  <h2 className="machineName-Bolder">{machineName}</h2>
                </div>
                <div>
                  <p className="progress">{status}</p>
                </div>
              </div>
            </div>
            <div className="Data_p">
              <div className="First_OP">
                {machineInfoDatas.map((item, index) => (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }} key={index} className="First_">
                    <p>{item.label}</p>
                    <span>
                      <b>{item.value}</b>
                    </span>
                  </div>
                ))}
              </div>
              <div className="Assigned_Engineer_box">
                <h3>Assigned Engineer</h3>
                {skyBlueBoxData.map((boxData, index) => (
                  <SkyBlueBox key={index} {...boxData} />
                ))}
              </div>
            </div>
          </div>
          <div className="Second_BOX">
            <div className="Top_Button">
              <p
                className={`Button ${activeSection === "ServiceTickets" ? "ActiveButton" : ""}`}
                onClick={() => handleButtonClick("ServiceTickets")}
              >
                Service Tickets
              </p>
              <p
                className={`Button ${activeSection === "EngineerNotes" ? "ActiveButton" : ""}`}
                onClick={() => handleButtonClick("EngineerNotes")}
              >
                Engineer Notes
              </p>
            </div>

            {activeSection === "ServiceTickets" && (
              <div className="Second_BOX_part" id="ServiceTickets">
                <div className="Flex-Details">
                  <div style={{ position: 'sticky', top: '0', backgroundColor: 'white' }} className="t-this">
                    <p className="Darks">SR No.</p>
                    <p className="requestDescriptions">Description</p>
                    <p className="Part_exchange">Status</p>
                    <p className="requestType">Type</p>

                  </div>
                  {loadingServiceRequests ? (
                    <div className="loader" style={{ textAlign: "center", marginTop: '400px' }}></div>
                  ) : serviceRequests.length === 0 ? (
                    <div>No service requests found.</div>
                  ) : (
                    serviceRequests.map((serviceRequest, idx) => (
                      <ServiceRequestItem
                        key={idx}
                        recordid={serviceRequest["Record Id"]}
                        srNo={serviceRequest["Serial No"]}
                        description={serviceRequest["Description"]}
                        type={serviceRequest["Type"]}
                        status={serviceRequest["Status"]}
                      />
                    ))
                  )}


                </div>
                <div className="btn-div">
                  <button
                    className="Create-request"
                    onClick={() => handleCreateServiceRequestClick(machineDetails.data["Record Id"])}
                  >
                    Create Service Ticket
                  </button>
                </div>
                {isPopupOpen && (
                  <PopupComponent2
                    isPopupOpen={isPopupOpen}
                    onClose={handleClosePopup}
                    machineName={machineName}
                    recordId={recordId}
                  />
                )}
              </div>
            )}

            {activeSection === "EngineerNotes" && (
              <div className="Second_BOX_part" id="EngineerNotes">
                <div className="Flex-Details">
                  <h3 style={{ marginLeft: '30px' }}>Your Notes</h3>
                  <div style={{ display: 'flex', width: '100%', textAlign: 'left', marginLeft: '50px', marginTop: '20px' }} className="Add_note">
                    {loadingEngineerNotes ? (
                      <div>Loading engineer notes...</div>
                    ) : Array.isArray(engineerNotes) ? (
                      engineerNotes.map((note, index) => (
                        <ul className="Notes" key={index}>
                          <li>
                            {note.note}
                            <strong>
                              <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/time.png" alt="time" />
                              {note.timestamp}
                            </strong>
                          </li>
                        </ul>
                      ))
                    ) : (
                      <div>No engineer notes found.</div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {activeSection === "MachineInfo" && (
              <div className="Second_BOX_part" id="MachineInfo">
                <div className="Flex-Details">
                  <h4>No Record Found</h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};

export default CAMdetails;
