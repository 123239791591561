import React, { useState, useEffect } from "react";
import SelectedOption from "../component/selected_options";
import Search from "../component/search";
import PartsInventory from "../component/Parts-Inventory";
import Pagination from "../component/Pagination";
import axios from "axios";

function Inventorylist() {
  const [manufacturers, setManufacturers] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [searchFilled, setSearchFilled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);

  const [iframeHeight, setIframeHeight] = useState(0);
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [equipmentList, setEquipmentList] = useState([]);


  const fetchManufacturers = async () => {
    try {
      const response = await axios.get("/jad_pe_manufacturer_list.php");
      setManufacturers(response.data);
      fetchModelList();
    }
    catch (error) {
      console.error("Error fetching manufacturers:", error.message);
    }

  };

  const fetchModelList = async () => {
    try {
      const response = await axios.get("/jad_pe_model_list.php");
      setModelList(response.data);
    } catch (error) {
      console.error("Error fetching model list:", error.message);
    }
  };


  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get('/jad_pe_part_listings.php', {
        params: {
          keyword: searchInput,
          manufacturer: selectedManufacturer,
          model: selectedModel,
          page: page,
          limit: 10
        }
      });
      const responseData = response.data;
      setLoading(false);
      if (responseData.error) {
        console.error("Error fetching data:", responseData.error);
        setNoResults(true);
        setEquipmentList([]);
      } else {
        if (Array.isArray(responseData.message)) {
          setEquipmentList(responseData.message);
          setTotalProducts(responseData.TotalProducts);
          setNoResults(responseData.message.length === 0);

          console.log("+++++++++++Total Products List===", responseData)
        } else {
          console.error("Invalid data format:", responseData);
          setNoResults(true);
          setEquipmentList([]);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };


  const sendIframeHeightToParent = () => {
    const newIframeHeight = document.body.scrollHeight;
    setIframeHeight(newIframeHeight);
    console.log("Sending iframe height:", newIframeHeight);
    window.parent.postMessage({ messageType: "iframeHeight", data: newIframeHeight }, "*");
  };


  useEffect(() => {
    fetchManufacturers();
  }, []);


  useEffect(() => {
    sendIframeHeightToParent();
  }, [totalProducts]);


  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, searchInput, selectedManufacturer, selectedModel]);


  const handleSearchInputChange = (query) => {
    const formattedQuery = query.replace(/ /g, '%20'); // Replace spaces with %20
    setSearchInput(formattedQuery);
    setCurrentPage(1);
  };


  const handleManufacturerChange = (manufacturer) => {
    const formattedManufacturer = manufacturer.replace(/\s+/g, '%20');
    setSelectedManufacturer(formattedManufacturer);
    setCurrentPage(1);
  };


  const handleModelChange = (model) => {
    const formattedModel = model.replace(/\s+/g, '%20');
    setSelectedModel(formattedModel);
    setCurrentPage(1);
  };


  const clearFilters = () => {
    setSearchInput("");
    setSelectedManufacturer("");
    setSelectedModel("");
    setCurrentPage(1);
  };


  const
    equipmentDataHtml = (
      <>
        {equipmentList.length > 0 ? (
          equipmentList.map((value, index) => (
            <PartsInventory
              key={value.JAD_ID}
              jad_id={value.JAD_ID}
              description={value.Product_Description}
              model={value.Model}
              Manufacturer={value.Manufacturer_Id}
              data={value}
              thumb={value.thumb}
              Inspection_Available={value.Is_Inspection_Available}
              vintage={value.Vintage}
              Condition={value.Condition}
              Parts_number={value["Part Number"]}
              Revision={value.Revision}
            />
          ))
        ) : (
          <div className="no-result"></div>
        )}
      </>
    );


  const paginationComponent = (
    <Pagination
      onPageChange={setCurrentPage}
      currentPage={currentPage}
      total={totalProducts}
    />
  );

  return (
    <>
      <div className="banner-image">
        <h1>Parts Inventory</h1>
      </div>
      <div className="filter-header-container">
        <div className="filter-container">
          <SelectedOption
            name="Manufacturer"
            values={manufacturers["Manufacturer"]}
            searchfunction={handleManufacturerChange}
            searchvalue={selectedManufacturer}
            type="single"
          />
          <SelectedOption
            name="Model"
            values={modelList["models"]}
            searchfunction={handleModelChange}
            searchvalue={selectedModel}
            type="single"
          />
          <button
            onClick={clearFilters}
            className={`clear-all-btn ${searchInput === "" && selectedManufacturer === "" && selectedModel === "" ? "d-none" : ""}`}
          >
            {searchFilled ? "Clear Search" : "Clear All Filters"}
          </button>
          <div className="showing-results">
            {totalProducts > 0 ? (
              <h5>
                Showing{" "}
                {currentPage === 1
                  ? `1-${Math.min(10, totalProducts)}`
                  : `${(currentPage - 1) * 10 + 1}-${Math.min(currentPage * 10, totalProducts)}`}{" "}
                of {totalProducts}
              </h5>
            ) : null}
          </div>
          <Search
            value={searchInput}
            searchfunction={handleSearchInputChange}
            setsearchfill={() => setSearchFilled(true)}
            setsearchempty={() => setSearchFilled(false)}
          />
        </div>
      </div>
      <div className="table-header">
        <div className="table-head-2 table-head Revision">
          <h5>Parts</h5>
        </div>
        <div className="table-head-2 table-head description">
          <h5>Description</h5>
        </div>
        <div className="table-head-2 table-head Part_Number">
          <h5>Manufacturer</h5>
        </div>
        <div className="table-head-2 table-head Part_Number">
          <h5>Part Number</h5>
        </div>
        <div className="table-head-3 table-head Revision">
          <h5>Revision</h5>
        </div>
        <div className="table-head-3 table-head Action">
          <h5>Actions</h5>
        </div>
      </div>


      {loading ? (
        <div className="loading-bar-container" style={{ height: `${iframeHeight}px` }}>
          <div className=" loading-bar">
            <div className="spinner"></div>
          </div>
        </div>
      ) : (
        <>
          {equipmentDataHtml}
          {paginationComponent}
        </>
      )}




    </>
  );
}
export default Inventorylist;
